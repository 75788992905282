var dropdown = {
    init: function () {

        var dropdowns = document.querySelectorAll( '.js-dropdown' );
        var dropdownInners = document.querySelectorAll( '.js-dropdown-inner' )

        if ( !dropdowns ) {
            return
        }

        function initdropdowns ( dropdown ) {

            var dropdownSelect = dropdown.getElementsByClassName( 'js-dropdown-selected' )[ 0 ];
            var fristDropdownItem = dropdown.querySelector( 'li:first-child input' );
            var initDropdownItem = fristDropdownItem.dataset.label;
            var dropdownInputs = dropdown.querySelectorAll( 'input' )

            setDropdownSelect( initDropdownItem );

            for ( var i = 0; i < dropdownInputs.length; i++ ) {
                dropdownInputs[ i ].addEventListener( 'change', function (e) {
                    e.stopPropagation();
                    setDropdownSelect( this.dataset.label )
                } );
            }

            dropdownSelect.onclick = function ( event ) {
                event.stopPropagation();

                if(utilities.checkActiveClass(event.target.nextElementSibling)){
                    hideAllDropdowns();
                }else{
                    hideAllDropdowns();
                    toggleDropdownSelect(event.target.nextElementSibling)
                }
            }

            document.addEventListener('click', documentClickHideDropdowns)
            document.addEventListener('keydown', documentEscHideDropdowns)

            function documentClickHideDropdowns ( e ) {
                hideAllDropdowns();
            }

             function documentEscHideDropdowns ( evt ) {
                evt = evt || window.event;
                if ( evt.keyCode == 27 ) {
                    hideAllDropdowns();
                }
            };

            function toggleDropdownSelect ( element ) {
                element.classList.toggle( 'active' );
            }

            function hideAllDropdowns () {
                if(!dropdownInners)return;
                for ( var i = 0; i < dropdownInners.length; i++ ) {
                    if ( utilities.checkActiveClass( dropdownInners[ i ] ) ) {
                        dropdownInners[ i ].classList.remove( 'active' );
                    }
                }
            }

            function setDropdownSelect ( content ) {
                dropdownSelect.innerHTML = content;
            }
        }

        for ( var index = 0; index < dropdowns.length; ++index ) {
            initdropdowns( dropdowns[ index ] )
        }
    }
}