var toast = {
	init: function () {

		// https://codepen.io/tobiasdev/pen/GjorQJ
		$(".toast-trigger").on("click", function (e) {
			// alert('HALT');
			e.preventDefault();
			var datatoast = $(this).attr("data-toast");
			if ($(this).hasClass("toast-auto") && !$("#" + datatoast).is(":visible")) {
				$("#" + datatoast).fadeIn(400).delay(5000).fadeOut(400);
			}
			else if (!$("#" + datatoast).is(":visible")) {
				$("#" + datatoast).fadeIn(400);
			};
		});

		// $(".close-toast").on("click", function(e){
		// 	e.preventDefault();
		// 	$("#" + closetoast).fadeOut(400);
		// });

	}
}