// $(document).ready(function(){
    // accordion.init();
    // stacktable.init();
// })
window.onload = function(){
    // backtotop.init();
    collapse.init();
    dropdown.init();
    fixedheader.init();
    search.init();  
    toast.init(); 
}

// $(window).resize(function() {
$( window ).on( "orientationchange", function( event ) {
    fixedheader.init();
  });